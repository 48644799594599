<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          {{ produto_id ? "Editar Produto" : "Adicionar Produto" }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="avatar">
                  <v-img :src="avatar" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" lg="10">
              <v-row dense class="px-4">
                <!-- Descrição -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="produto.descricao"
                    label="Descrição"
                    dense
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- Valor -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.valor"
                    label="Valor"
                    dense
                    required
                    :rules="formRules"
                    v-currency="vCurrencyOptions"
                  ></v-text-field>
                </v-col>

                <!--Subdescrição-->
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="produto.sub_descricao"
                    label="Subdescrição"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Tipo -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model="produto.tipo"
                    label="Tipo"
                    dense
                    :items="tipos"
                    item-value="id"
                    item-text="value"
                    :rules="formRules"
                  ></v-select>
                </v-col>

                <!-- Categoria -->
                <v-col class="" cols="12" md="4">
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="produto.categoria_id"
                      label="Categoria"
                      dense
                      :items="categorias"
                      item-value="id"
                      item-text="value"
                      :rules="formRules"
                      class="mr-2"
                    ></v-select>

                    <NovaCategoria :update="getCategorias" />
                  </div>
                </v-col>

                <!-- Detalhes -->
                <v-col class="" cols="12" md="12">
                  <div class="d-flex flex-row">
                    <v-text-field
                      v-model="produto.detalhes"
                      label="Detalhes"
                      dense
                      class="mr-2"
                    ></v-text-field>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="snackbar = !snackbar"
                          class="xbColor--text"
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Para inserir uma quebra de linha digite um hífen "-"<br />
                        <b>Exemplo:</b>
                        Capa:Comum - Páginas: 312 - Idioma: Português
                      </span>
                    </v-tooltip>

                    <v-snackbar v-model="snackbar">
                      Para inserir uma quebra de linha digite um hífen "-"<br />
                      <b>Exemplo:</b>
                      Capa:Comum - Páginas: 312 - Idioma: Português

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="xbColor"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Fechar
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/loja/produtos' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="onSubmit"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon v-if="!produto_id" left>mdi-plus</v-icon>
            {{ produto_id ? "Atualizar" : "Adicionar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { inputRequired } from "@/plugins/utils.js";
import {
  allCategorias,
  createProduto,
  getProduto,
  updateProduto,
} from "@/api/admin/loja.js";
import { formatCurrency, selectTipo } from "@/filters";

export default {
  name: "NovoProduto",

  data() {
    return {
      produto: {},
      avatar: null,
      avatarUpload: null,
      loading: false,
      hoverFile: false,
      validForm: true,
      formRules: [inputRequired],
      tipos: selectTipo,
      rawValor: null,
      categorias: [],
      update: 0,
      snackbar: false,
    };
  },

  props: ["produtoId"],

  components: {
    vueDropzone: vue2Dropzone,
    NovaCategoria: () => import("./components/NovaCategoria.vue"),
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    vCurrencyOptions() {
      return {
        precision: 2,
        distractionFree: false,
      };
    },

    produto_id() {
      return this.$route.params?.produtoId;
    },
  },

  watch: {
    "produto.valor"(val) {
      this.rawValor = this.$ci.parse(val);
    },
  },

  methods: {
    fileAdded(file) {
      this.avatarUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    async getCategorias() {
      const response = await allCategorias();
      this.categorias = response
        .filter((item) => item.status === 1)
        .map((item) => {
          return {
            id: item.id,
            value: item.descricao,
          };
        });
    },

    createProduto() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const formdata = new FormData();

        if (this.avatarUpload) {
          formdata.append("imagem", this.avatarUpload, this.avatarUpload.name);
        }

        for (let key in this.produto) {
          if (key === "valor") {
            formdata.append(key, this.rawValor);
          } else {
            formdata.append(key, this.produto[key]);
          }
        }

        formdata.append("status", "1");

        createProduto(formdata)
          .then((response) => {
            if (response === "ok") {
              this.$toast.success("Criado com sucesso");
              this.loading = false;
              this.$router.push({ path: "/loja/produtos" });
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$toast.error("Não Validou!");
      }
    },

    updateProduto() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const formdata = new FormData();

        if (this.avatarUpload) {
          formdata.append("imagem", this.avatarUpload, this.avatarUpload.name);
        } else if (this.avatar) {
          formdata.append("imagem", this.avatar);
        }

        formdata.append("descricao", this.produto.descricao);
        formdata.append("valor", Number(this.rawValor));
        formdata.append("sub_descricao", this.produto.sub_descricao);
        formdata.append("tipo", this.produto.tipo);
        formdata.append("categoria_id", this.produto.categoria_id);
        formdata.append("detalhes", this.produto.detalhes);
        formdata.append("status", this.produto.status);

        updateProduto(formdata, this.produto_id)
          .then(() => {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
            this.$router.push({ path: "/loja/produtos" });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    getProduto() {
      getProduto(this.$route.params?.produtoId)
        .then((response) => {
          this.produto = {
            ...response,
            valor: formatCurrency(response.valor),
          };
          this.avatar = response.imagem;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    onSubmit() {
      if (this.produto_id) this.updateProduto();
      else this.createProduto();
    },
  },

  async mounted() {
    this.loading = true;
    await this.getCategorias();
    if (this.$route.params?.produtoId) await this.getProduto();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
